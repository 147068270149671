<template>
  <div class="main no-flex">
    <b-tabs v-model="tabIndex">
      <b-tab>
        <template #title>
          <strong
            style="font-weight: 500; color: black"
          >{{ $t('ReturnProcess') }}</strong>
        </template>
        <div
          v-if="tabIndex == 0"
          style="display:flex;gap:15px"
        >
          <ReturnProcessMaterial @pageNumber="handlePage" />
          <ReturnProcessToStockMaterials :page-of-material="pageOfMaterial" />
        </div>
      </b-tab>
      <b-tab
        style="display:flex;gap:15px;width:100%"
      >
        <template #title>
          <strong
            style="font-weight: 500; color: black"
          >{{ $t('ReturnReporting') }}</strong>
        </template>
        <div
          v-if="tabIndex == 1"
        >
          <ReturnReportingMaterials />
        </div>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import ReturnProcessToStockMaterials from '@/components/materialsNominvative/ReturnProcessToStockMaterials.vue'
import ReturnProcessMaterial from '@/components/materialsNominvative/ReturnProcessMaterial.vue'
import ReturnReportingMaterials from '@/components/materialsNominvative/ReturnReportingMaterials.vue';

export default {
  components: {
    ReturnProcessMaterial,
    ReturnProcessToStockMaterials,
    ReturnReportingMaterials,
  },
  data() {
    return {
      pageOfMaterial: '',
      tabIndex: 0,
    };
  },
  methods: {
    handlePage(page) {
      this.pageOfMaterial = page;
    },
  },
}


</script>
  <style>
  .mainn{
    padding: 75px 15px 15px 120px;
      display: flex;
      gap: 30px;
  }

  </style>
